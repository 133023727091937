import styled from "styled-components"
import Device from "../utilities/Breakpoints"
import "./fonts/stylesheet.css"

const BASE_HEADER_STYLE = `
    margin: 0;

    font-family: Founders Grotesk, sans-serif;
`

export const Display1Styles = `
    ${BASE_HEADER_STYLE}

    font-weight: 500;
    font-size: 38px;
    line-height: 110%;

    @media ${Device.tablet} {
        font-size: 74px;
    }
`

export const Display1 = styled.h1`
    ${Display1Styles}
`

export const Heading1Styles = `
    ${BASE_HEADER_STYLE}
    font-weight: 500;
    font-size: 38px;
    line-height: 110%;

    @media ${Device.tablet} {
        font-size: 56px;
    }
`

export const Heading1 = styled.h1`
    ${Heading1Styles}
`

export const Heading2Styles = `
    ${BASE_HEADER_STYLE}

    font-weight: 500;
    font-size: 29px;
    line-height: 34px;

    @media ${Device.tablet} {
        font-size: 36px;
        line-height: 42px;
    }
`

export const Heading2 = styled.h2`
    ${Heading2Styles}
`

export const Heading3Styles = `
    ${BASE_HEADER_STYLE}

    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    @media ${Device.tablet} {
        font-size: 26px;
        line-height: 31px;
    }
`

export const Heading3 = styled.h3`
    ${Heading3Styles}
`

export const Heading4 = styled.h4`
    ${BASE_HEADER_STYLE}

    font-weight: normal;
    font-size: 18px;
    line-height: 133%;
    letter-spacing: 0.02em;

    @media ${Device.tablet} {
        font-size: 20px;
    }
`
