import React from 'react';
import styled from 'styled-components';
import Colors from '../utilities/Colors';

export default styled.button`
    background: none;
    border: 0;
    appearance: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;

    svg path {
        transition: fill .5s ease;
    }

    &:hover svg path {
        fill: ${Colors.black}CC !important;
    }
`;