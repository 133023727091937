import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useField } from "formik"
import { gsap } from "gsap"

import Colors from "../../utilities/Colors"
import { Mono13, Text13 } from "../../typography/Text"
import { StyledWrapper as DropdownWrapper } from "../select/Dropdown"

const StyledWrapper = styled.div`
    position: relative;

    box-sizing: border-box;
    display: inline-flex;

    height: 68px;

    border: 2px solid ${props => (props.light ? Colors.white : Colors.black)};

    & + &,
    ${DropdownWrapper} + & {
        border-top: 0;
    }

    transition: background 0.2s ease;

    ${props => props.focused && `background: ${Colors.orange}40;`}
`

const StyledPlaceholder = styled(Mono13)`
    position: absolute;

    top: 50%;
    left: 20px;

    transform: translateY(-50%);
    pointer-events: none;
`

const StyledLabel = styled(Text13)`
    opacity: 0;
    position: absolute;

    left: 20px;
    top: 12px;
`

const StyledError = styled(Text13)`
    position: absolute;

    left: 20px;
    top: 12px;
`

const StyledInput = styled(Mono13).attrs({
    as: "input",
})`
    width: 100%;

    margin-top: 20px;
    padding: 0 20px;

    background: 0;
    border: 0;
    appearance: unset;

    color: ${props => (props.light ? Colors.white : Colors.black)};

    &:focus {
        outline: none;
    }
`

function Label({ active, children, ...props }) {
    const [animation, setAnimation] = useState(null)

    const labelRef = useRef(null)
    const placeholderRef = useRef(null)

    useEffect(() => {
        setAnimation(
            gsap
                .timeline()
                .from(labelRef.current, { y: -10, duration: 0.2 })
                .to(labelRef.current, { opacity: 1, duration: 0.2 }, "<")
                .to(placeholderRef.current, { opacity: 0, duration: 0.2 }, "<")
                .pause()
        )
    }, [labelRef, placeholderRef])

    useEffect(() => {
        if (animation !== null) {
            if (active) {
                animation.play()
            } else {
                animation.reverse()
            }
        }
    }, [active, animation])

    return (
        <>
            <StyledLabel ref={labelRef} as="label" {...props}>
                {children}
            </StyledLabel>
            <StyledPlaceholder ref={placeholderRef} as="span" {...props}>
                {children}
            </StyledPlaceholder>
        </>
    )
}

export function TextInput({
    label,
    value,
    onBlur = () => {},
    light,
    error,
    initialValue,
    ...props
}) {
    const [focus, setFocus] = useState(false)

    function wrappedOnBlur(e) {
        setFocus(false)
        return onBlur(e)
    }

    return (
        <StyledWrapper light={light} focused={focus || error}>
            {!error && <Label active={focus || value}>{label}</Label>}
            {error && <StyledError active>{error}</StyledError>}
            <StyledInput
                {...props}
                value={value}
                light={light}
                onFocus={() => setFocus(true)}
                onBlur={wrappedOnBlur}
            />
        </StyledWrapper>
    )
}

export default function ({ label, ...props }) {
    const [field, meta, helpers] = useField(props)

    return <TextInput {...field} {...props} {...meta} label={label} />
}
