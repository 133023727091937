import styled from "styled-components"
import { Heading3, Heading3Styles } from "../../typography/Headings"
import { Text16 } from "../../typography/Text"
import Breakpoints from "../../utilities/Breakpoints"
import Colors from "../../utilities/Colors"

export default styled(Heading3)`
    color: ${Colors.orange};
    display: block;
    padding: 40px 0;

    border-top: 2px solid ${Colors.black};
    border-bottom: 2px solid ${Colors.black};

    > ${Text16} {
        ${Heading3Styles}
        margin: 0 !important;
        max-width: unset !important;
    }

    text-align: center;

    @media ${Breakpoints.tablet} {
        padding: 35px 10px;
    }
`
