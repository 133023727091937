import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Mono13 } from "../../typography/Text"
import Breakpoints from "../../utilities/Breakpoints"
import Colors from "../../utilities/Colors"

const ExpandItem = styled.button`
    display: flex;
    justify-content: space-between;

    width: 100%;

    outline: none;

    padding: 18px 0;

    appearance: none;

    border: 0;
    background: unset;
    color: ${Colors.white};

    ${props =>
        props.open &&
        `
        svg line:nth-child(2) {
            display: none;
        }
    `}
`

const Wrapper = styled.div`
    border-top: ${Colors.white} 1px solid;
    border-bottom: ${Colors.white} 1px solid;

    li a {
        text-decoration: none;
        color: ${Colors.white};
    }

    @media ${Breakpoints.tablet} {
        flex: 1 1 calc(66% - 20px);
        padding-left: 20px;
        border: 0;

        display: flex;
        justify-content: flex-end;

        ${ExpandItem} {
            display: none;
        }
    }

    @media ${Breakpoints.desktop} {
        flex: 1 1 33%;
        justify-content: flex-start;
        padding-top: 45px;
    }
`

const NavigationMenu = styled.ul`
    list-style: none;

    display: ${props => (props.open ? "block" : "none")};
    margin-top: ${props => (props.open ? "0" : "16px")};
    padding: 0;

    li {
        margin: 15px 0;
    }

    li a {
        text-decoration: none;
        color: ${Colors.white};
    }

    @media ${Breakpoints.tablet} {
        margin-top: 0;
        width: 80%;
        display: grid;
        grid-template-columns: auto auto;

        li {
            margin-top: 0;
            margin-bottom: 25px;
            color: ${Colors.white};

            a {
                position: relative;

                text-decoration: none;
                color: ${Colors.white};

                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%) scaleX(0);
                    bottom: -8px;
                    width: 100%;
                    height: 2px;
                    background: ${Colors.white};
                    transition: transform 0.5s ease;
                    transform-origin: left;
                }

                &.active-navitem::after,
                &:hover ::after {
                    transform: translateX(-50%) scaleX(1);
                }
            }
        }
    }
`

export default function AccordionNav({}) {
    const [open, setOpen] = useState(false)

    return (
        <Wrapper>
            <ExpandItem open={open} onClick={() => setOpen(!open)}>
                <Mono13>Explore Otabo</Mono13>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line
                        x1="8.21774e-08"
                        y1="8"
                        x2="16"
                        y2="8"
                        stroke="white"
                        strokeWidth="2"
                    />
                    <line
                        x1="8"
                        y1="-4.37114e-08"
                        x2="8"
                        y2="16"
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
            </ExpandItem>
            <NavigationMenu open={open}>
                <li>
                    <Mono13 as={Link} to="/">
                        Home
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/services">
                        Services
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/working-together">
                        Working Together
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/startup-shop">
                        Startup Shop
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/why-otabo">
                        Why Otabo
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/resources">
                        Resources
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/careers">
                        Careers
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/press">
                        Press
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/contact">
                        Contact
                    </Mono13>
                </li>
                <li>
                    <Mono13 as={Link} to="/account/login">
                        Login
                    </Mono13>
                </li>
            </NavigationMenu>
        </Wrapper>
    )
}
