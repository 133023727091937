import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { useField } from "formik"
import { gsap } from "gsap"

import Colors from "../../utilities/Colors"
import { Mono13, Text13 } from "../../typography/Text"

function DropdownIndicator() {
    return (
        <svg
            width="9"
            height="10"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.58769 6.11919H4.89669L4.24219 6.90276L3.58769 6.11919Z"
                stroke="currentcolor"
                stroke-width="3"
            />
        </svg>
    )
}

export const StyledWrapper = styled.div`
    position: relative;

    box-sizing: border-box;
    display: inline-flex;

    height: 68px;

    border: 2px solid ${Colors.black};

    & + & {
        border-top: 0;
    }

    transition: background 0.2s ease, color 0.2s ease;

    ${props => props.open && `background: ${Colors.orange};`}
    ${props => props.open && `color: ${Colors.white};`}
`

const StyledPlaceholder = styled(Mono13)`
    position: absolute;

    top: 50%;
    left: 20px;

    transform: translateY(-50%);
    pointer-events: none;
`

const StyledLabel = styled(Text13)`
    opacity: 0;
    position: absolute;

    left: 20px;
    top: 12px;
`

function Label({ active, children, ...props }) {
    const [animation, setAnimation] = useState(null)

    const labelRef = useRef(null)
    const placeholderRef = useRef(null)

    useEffect(() => {
        setAnimation(
            gsap
                .timeline()
                .from(labelRef.current, { y: -10, duration: 0.2 })
                .to(labelRef.current, { opacity: 1, duration: 0.2 }, "<")
                .to(placeholderRef.current, { opacity: 0, duration: 0.2 }, "<")
                .pause()
        )
    }, [labelRef, placeholderRef])

    useEffect(() => {
        if (animation !== null) {
            if (active) {
                animation.play()
            } else {
                animation.reverse()
            }
        }
    }, [active, animation])

    return (
        <>
            <StyledLabel ref={labelRef} as="label" {...props}>
                {children}
            </StyledLabel>
            <StyledPlaceholder ref={placeholderRef} as="span" {...props}>
                {children}
            </StyledPlaceholder>
        </>
    )
}

function StyledSelect({ ...props }) {
    const styles = {
        container: existing => ({
            ...existing,
            width: "100%",
            minWidth: "200px",
            height: "100%",
        }),
        control: (existing, { isSelected }) => ({
            display: "flex",
            minHeight: 38,
            outline: 0,
            position: "relative",
            boxSizing: "border-box",
            border: 0,
            height: "100%",
        }),
        indicatorsContainer: (existing, { selectProps }) => ({
            ...existing,
            position: "relative",
            bottom: 9,
            marginRight: 20,
            transform: selectProps.menuIsOpen
                ? "rotate(180deg) translateY(-2px)"
                : "",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        dropdownIndicator: (existing, { selectProps }) => ({
            ...existing,
            color: selectProps.menuIsOpen ? Colors.white : Colors.black,
        }),
        placeholder: () => ({
            display: "none",
        }),
        menu: existing => ({
            ...existing,
            borderRadius: 0,
            boxShadow: 0,
            borderTop: `2px solid ${Colors.black}`,
            borderBottom: `2px solid ${Colors.black}`,
            background: Colors.neutral1,
            top: "82%",
            width: "100%",
        }),
        menuList: existing => ({
            ...existing,
            paddingTop: 0,
            paddingBottom: 0,
            borderLeft: `2px solid ${Colors.black}`,
            borderRight: `2px solid ${Colors.black}`,
            width: "calc(100% + 4px)",
            position: "relative",
            left: -2,
        }),
        option: (existing, { isHovered }) => ({
            ...existing,
            fontFamily: "Founders Grotesk Mono, sans-serif",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: 13,
            padding: "16px 20px",
            outline: 0,
            background: isHovered ? `${Colors.orange}40` : 0,
            cursor: "pointer",
            color: Colors.black,
            "&:hover": {
                background: `${Colors.orange}40`,
            },
        }),
        singleValue: (existing, { selectProps }) => ({
            ...existing,
            fontFamily: "Founders Grotesk Mono, sans-serif",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: 13,
            transition: "color .2s ease",
            color: selectProps.menuIsOpen ? Colors.white : Colors.black,
            position: "relative",
            top: 18,
        }),
        valueContainer: existing => ({
            ...existing,
            padding: "0 18px 0",
        }),
    }

    return (
        <Select
            styles={styles}
            components={{ DropdownIndicator }}
            isSearchable={false}
            {...props}
            open
        />
    )
}

export default function Dropdown({ label, options = [], ...props }) {
    const [isOpen, setIsOpen] = useState(false)
    const [field, meta, helpers] = useField(props)

    const { value } = meta
    const { setValue } = helpers

    function handleChange(option) {
        setValue(option.value)
    }

    return (
        <StyledWrapper open={isOpen}>
            <Label htmlFor={field.name} active={!!value}>
                {label}
            </Label>
            <StyledSelect
                id={field.name}
                name={field.name}
                onBlur={field.onBlur}
                options={options}
                onChange={handleChange}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
            />
        </StyledWrapper>
    )
}
