import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import styled from "styled-components"
import Logo from "../../Logo"
import { Mono13, Mono13Styles } from "../../typography/Text"
import Colors from "../../utilities/Colors"
import NavigationIcon from "./NavigationIcon"
import Breakpoints from "../../utilities/Breakpoints"
import PrimaryButton from "../../buttons/PrimaryButton"
import { Link } from "gatsby"

const Wrapper = styled.header`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    background: ${props => props.color || Colors.neutral1};
    border-bottom: 1px solid ${Colors.black}1A;
`

const MarginBlock = styled.div`
    background: ${props => props.color || Colors.neutral1};
    padding-bottom: 100px;
`

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${Breakpoints.desktop} {
        padding: 20px 60px;
        max-width: 1440px;
        margin: 0 auto;

        ${PrimaryButton} {
            width: 150px;
            text-align: center;
            white-space: nowrap;
            margin-left: 20px;
        }
    }
`

const LogoMenuLayout = styled.div`
    display: flex;
    align-items: center;
    padding: 0 18px;

    > button {
        margin-right: 15px;
    }

    > a > svg {
        position: relative;
        top: 2px;
        transition: color 0.5s ease;
    }

    > a:hover > svg {
        color: ${Colors.orange};
    }

    @media ${Breakpoints.desktop} {
        svg {
            margin-left: 0;
            position: static;
        }

        button {
            display: none;
        }
    }
`

const NavLayout = styled.nav`
    display: ${props => (props.open ? "block" : "none")};
    z-index: 99;

    clip-path: inset(0 0 0 0);

    position: absolute;
    top: 50px;

    width: 100%;

    background: ${Colors.neutral2};

    ul {
        list-style: none;
        padding: 40px 0 35px;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 50px);
        box-sizing: border-box;
    }

    li {
        margin-bottom: 40px;
        padding: 0 18px;

        a {
            position: relative;
            color: ${Colors.black};
            text-decoration: none;

            ${Mono13Styles}

            transition: color 0.5s ease;

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%) scaleX(0);
                bottom: -8px;
                width: 100%;
                height: 2px;
                background: ${Colors.orange};
                transition: transform 0.5s ease;
                transform-origin: left;
            }

            &:hover {
                color: ${Colors.orange};
            }

            &.active-navitem::after,
            &:hover ::after {
                transform: translateX(-50%) scaleX(1);
            }
        }
    }

    @media ${Breakpoints.desktop} {
        display: inline-block;
        position: static;
        z-index: inherit;

        clip-path: unset !important;
        background: inherit;

        ul {
            height: auto;
            flex-direction: row;
            padding: 0;

            justify-content: flex-end;
            align-items: center;
        }

        li {
            opacity: 1 !important;
            transform: unset !important;

            margin: 0 20px;

            padding: 0;
        }
    }
`

const NavActionLayout = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    width: 100%;

    border-top: 1px solid ${Colors.neutral1};
    padding-top: 35px;

    @media ${Breakpoints.desktop} {
        display: none;
    }
`

const NAVIGATION_ITEMS = [
    { label: "Services", link: "/services" },
    { label: "Working Together", link: "/working-together" },
    { label: "Startup Shop", link: "/startup-shop" },
    { label: "Why Otabo", link: "/why-otabo" },
    { label: "Resources", link: "/resources" },
]

export default function Header({ color }) {
    const [navOpen, setNavOpen] = useState(false)
    const [timeline, setTimeline] = useState(null)
    const navRef = useRef(null)
    const itemRefs = useRef([])

    useEffect(() => {
        setTimeline(
            gsap
                .timeline()
                .fromTo(
                    navRef.current,
                    {
                        clipPath: "inset(0 0 100% 0)",
                    },
                    {
                        duration: 0.25,
                        clipPath: "inset(0 0 0% 0)",
                    }
                )
                .fromTo(
                    itemRefs.current,
                    { yPercent: 25, opacity: 0 },
                    {
                        yPercent: 0,
                        opacity: 1,
                        ease: "power2",
                        stagger: 0.1,
                        duration: 0.5,
                    }
                )
                .pause()
        )
    }, [navRef])

    function toggleNavigation() {
        if (!navOpen) {
            setNavOpen(true)
            timeline.play()
        } else {
            timeline.reverse().then(() => {
                setNavOpen(!navOpen)
            })
        }
    }

    return (
        <>
            <Wrapper color={color}>
                <HeaderWrapper>
                    <LogoMenuLayout>
                        <NavigationIcon
                            open={navOpen}
                            onClick={toggleNavigation}
                        />
                        <Link to="/">
                            <Logo />
                        </Link>
                    </LogoMenuLayout>
                    <NavLayout ref={navRef} open={navOpen}>
                        <ul>
                            {NAVIGATION_ITEMS.map(item => (
                                <li
                                    key={item.link}
                                    ref={ref => itemRefs.current.push(ref)}
                                >
                                    <Link
                                        activeClassName="active-navitem"
                                        partiallyActive={true}
                                        to={item.link}
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            ))}
                            <NavActionLayout
                                ref={ref => itemRefs.current.push(ref)}
                            >
                                <li>
                                    <a>Log In</a>
                                </li>
                            </NavActionLayout>
                        </ul>
                    </NavLayout>
                    <PrimaryButton as={Link} to="/contact">
                        <Mono13>Get Started</Mono13>
                    </PrimaryButton>
                </HeaderWrapper>
            </Wrapper>
            <MarginBlock color={color} />
        </>
    )
}
