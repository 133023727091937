import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ArticleHeader from "../components/Article/ArticleHeader/ArticleHeader"
import { Text16 } from "../components/typography/Text"
import Breakpoints from "../components/utilities/Breakpoints"
import Layout from "../layouts/default"
import Colors from "../components/utilities/Colors"
import {
    Heading1,
    Heading2,
    Heading3,
    Heading4,
} from "../components/typography/Headings"
import ArticleBlockQuote from "../components/Article/ArticleHeader/ArticleBlockQuote"
import Image from "../components/image/Image"
import BackgroundSection from "../components/BackgroundSection/BackgroundSection"
import SEO from "../components/seo"

const ArticleWrapper = styled.div`
    max-width: 990px;
    padding: 30px 0 50px;
    margin: 0 auto;

    @media ${Breakpoints.tablet} {
        padding: 60px 0 100px;
    }
`

const ArticleBody = styled.div`
    max-width: 660px;
    margin: 0 auto;
    padding: 0 18px;

    ${Text16} {
        margin: 20px auto;
        max-width: 500px;
    }

    h1,
    h2,
    h3 {
        margin: 40px auto 16px;
        max-width: 500px;
    }

    h4,
    h5 {
        margin: 20px auto 8px;
        max-width: 500px;
    }

    ${ArticleBlockQuote} {
        max-width: 660px;
    }
`

const Hyperlink = styled(Text16)`
    color: ${Colors.orange};
`

function renderContent(content) {
    switch (content.nodeType) {
        case "paragraph":
            return <Text16>{content.content.map(renderContent)}</Text16>
        case "hyperlink":
            return (
                <Hyperlink as="a" href={content.data.uri}>
                    {content.content.map(renderContent)}
                </Hyperlink>
            )
        case "list-item":
            return <Text16 as="li">{content.content.map(renderContent)}</Text16>
        case "text":
            let textNode = content.value

            content.marks.forEach(mark => {
                if (mark.type === "bold") {
                    textNode = <strong>{textNode}</strong>
                } else if (mark.type === "italic") {
                    textNode = <i>{textNode}</i>
                } else if (mark.type === "underline") {
                    textNode = <u>{textNode}</u>
                }
            })

            return textNode
        default:
            return content.value
    }
}

function renderArticleBody(body) {
    const bodyObj = JSON.parse(body)

    return bodyObj.content.map(block => {
        switch (block.nodeType) {
            case "heading-1":
                return (
                    <Heading1 as="h2">
                        {block.content.map(renderContent)}
                    </Heading1>
                )
            case "heading-2":
                return (
                    <Heading2 as="h3">
                        {block.content.map(renderContent)}
                    </Heading2>
                )

            case "heading-3":
                return (
                    <Heading3 as="h4">
                        {block.content.map(renderContent)}
                    </Heading3>
                )
            case "heading-4":
                return (
                    <Heading4 as="h5">
                        {block.content.map(renderContent)}
                    </Heading4>
                )
            case "unordered-list":
                return <ul>{block.content.map(renderContent)}</ul>
            case "ordered-list":
                return <ol>{block.content.map(renderContent)}</ol>
            case "blockquote":
                return (
                    <ArticleBlockQuote>
                        {block.content.map(renderContent)}
                    </ArticleBlockQuote>
                )
            case "embedded-asset-block":
                return (
                    <Image src={block.data.target.fields.file["en-US"].url} />
                )
            default:
                return <Text16>{block.content.map(renderContent)}</Text16>
        }
    })
}

export default function Article({ pageContext }) {
    const { articleTitle, articleImage, articleBody } = pageContext

    return (
        <Layout>
            <SEO title={articleTitle} />
            <BackgroundSection background={Colors.neutral1}>
                <ArticleWrapper>
                    <ArticleHeader
                        title={articleTitle}
                        image={articleImage.file.url}
                    />

                    <ArticleBody>
                        {renderArticleBody(articleBody.articleBody)}
                    </ArticleBody>
                </ArticleWrapper>
            </BackgroundSection>
        </Layout>
    )
}
