import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

const Button = styled.button`
    height: 18px;
    width: 16px;
    padding: 0;

    appearance: none;

    outline: none;

    border: 0;
    background: unset;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export default function NavigationIcon({ open = false, onClick, ...props }) {
    const [timeline, setTimeline] = useState(null);

    const line1 = useRef(null)
    const line2 = useRef(null)
    const line3 = useRef(null)

    useEffect(() => {
        setTimeline(gsap.timeline()
            .to(line2.current, { y: -16, duration: .20, })
            .to(line1.current, { y: -8, duration: .20, }, "<")
            .to(line3.current, { opacity: 0, duration: 0 })
            .to(line2.current, { rotation: 45, duration: .25 })
            .to(line1.current, { rotation: -45, duration: .25 }, "<")
            .to(line2.current, { x: 2, y: -14, duration: .25 }, "<")
            .to(line1.current, { x: 2, y: 6.80, duration: .25 }, "<").pause()
        )
    }, [line1, line2, line3])

    useEffect(() => {
        if (timeline !== null) {
            if (open === true) {
                timeline.play()
            } else {
                timeline.reverse()
            }
        }
    }, [open, timeline])

    return (
        <Button role="button" onClick={onClick} {...props}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="9" x2="18" y2="9" stroke="black" strokeWidth="2" ref={line1} />
                <line y1="17" x2="18" y2="17" stroke="black" strokeWidth="2" ref={line2} />
                <line y1="1" x2="18" y2="1" stroke="black" strokeWidth="2" ref={line3} />
            </svg>
        </Button>
    )

}