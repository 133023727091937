import React from "react"
import styled from "styled-components"
import Image from "../../image/Image"
import { Display1 } from "../../typography/Headings"
import Breakpoints from "../../utilities/Breakpoints"

const Wrapper = styled.div`
    max-with: 990px;
    margin: 0 auto;
    text-align: center;

    // Image
    > div:last-child {
        max-width: 990px;
        width: 100%;
        height: 150px;
        margin: 30px 0;
    }

    @media ${Breakpoints.tablet} {
        > div:last-child {
            height: 250px;
            margin: 60px 0;
        }
    }

    @media ${Breakpoints.desktop} {
        > div:last-child {
            height: 390px;
        }
    }
`

export default function ArticleHeader({ title, image }) {
    return (
        <Wrapper>
            <Display1>{title}</Display1>
            <Image src={image} />
        </Wrapper>
    )
}
