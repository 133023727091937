import React from "react"
import { Formik, Form } from "formik"
import TextInput from "../input/text/TextInput"
import PrimaryButton from "../buttons/PrimaryButton"

export default function NewsletterForm({ onSubmit }) {
    const initialValues = {
        fullName: "",
        email: "",
        company: "",
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
                <TextInput name="fullName" label="Full Name" light />
                <TextInput name="email" label="Email" light />
                <TextInput name="company" label="Your Company Name" light />
                <PrimaryButton style={{ marginTop: 25 }} light>
                    Sign Up
                </PrimaryButton>
            </Form>
        </Formik>
    )
}
