import styled from "styled-components"
import { Mono13 } from "../typography/Text"
import Colors from "../utilities/Colors"

export default styled(Mono13).attrs(props => ({ as: props.as || "button" }))`
    padding: 18px 26px;

    appearance: none;
    border: 0;
    background: ${props => (props.light ? Colors.white : Colors.orange)};
    color: ${props => (props.light ? Colors.black : Colors.white)};
    outline: none;
    cursor: pointer;
    text-decoration: none;

    transition: background 0.5s ease;

    &:hover {
        background: ${props =>
            props.light ? Colors.orange : `${Colors.black}CC`};
        color: ${props => (props.light ? Colors.white : Colors.white)};
    }
`
