import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import gsap from "gsap"
import { useInView } from "react-intersection-observer"

import Colors from '../utilities/Colors';

const EmptyImage = styled.div`
    position: relative;

    background: ${Colors.neutral2};

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

const ImageWrapper = styled.div`
    img {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;

        object-fit: ${props => props.objectFit};

        &.will-fade-in {
          opacity: 0;
          transform: translateY(20px);
        }
    }
`;

export default function Image({ decoration: Decoration = (React.Fragment), src, decorationProps, objectFit = "cover", fadeIn = false, ...props }) {
    const image = useRef(null)
    const [animation, setAnimation] = useState(null)
    const [animateDecoration, setAnimateDecoration] = useState(false)
    const { ref, inView } = useInView({ threshold: 0.99, triggerOnce: true })
    const decorationExists = typeof Decoration === 'function'

    if (fadeIn && decorationExists) decorationProps = {...decorationProps, animateDecoration}

    useEffect(() => {
        if (image && fadeIn) {
            setAnimation(
                gsap
                    .timeline({ paused: true })
                    .to(
                        image.current,
                        { opacity: 1, y: 0, duration: 0.7, onComplete: () => {
                            if (decorationExists) setAnimateDecoration(true)
                        } }
                    )
            )
        }
    }, [image])

    useEffect(() => {
        if (animation && inView) {
            animation.play()
        }
    }, [inView, animation])

    if (!src) {
        return (
            <Decoration {...decorationProps}>
                <EmptyImage {...props}>
                    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M49.3493 0.686279H0.949125C0.505087 0.686279 0.172058 1.01931 0.172058 1.46335V49.8635C0.172058 50.3075 0.505087 50.6406 0.949125 50.6406H49.3493C49.7933 50.6406 50.1263 50.3075 50.1263 49.8635V1.46335C50.1263 1.01931 49.7933 0.686279 49.3493 0.686279ZM48.5722 49.0864H1.72619V2.24041H48.6277V49.0864H48.5722Z" fill="white" />
                        <path d="M11.9391 18.2813C15.2139 18.2813 17.8781 15.6171 17.8781 12.3423C17.8781 9.06749 15.2139 6.40326 11.9391 6.40326C8.66431 6.40326 5.94458 9.06749 5.94458 12.3423C5.94458 15.6171 8.66431 18.2813 11.9391 18.2813ZM11.9391 7.90189C14.3813 7.90189 16.3795 9.90006 16.3795 12.3423C16.3795 14.7845 14.3813 16.7827 11.9391 16.7827C9.49689 16.7827 7.49871 14.7845 7.49871 12.3423C7.49871 9.90006 9.49689 7.90189 11.9391 7.90189Z" fill="white" />
                        <path d="M17.101 27.5505L6.16656 42.7588C6.00005 42.9808 6.00005 43.3138 6.11106 43.5359C6.22207 43.8134 6.49959 43.9799 6.77712 43.9799H17.8781H29.2565H43.4658C43.7433 43.9799 44.0208 43.8134 44.1318 43.5914C44.2428 43.3693 44.2428 43.0363 44.0763 42.8143L30.9772 22.8881C30.8107 22.6661 30.5887 22.5551 30.3111 22.5551C30.0336 22.5551 29.8116 22.6661 29.6451 22.8881L22.9845 33.656L18.3221 27.5505C18.1556 27.384 17.9336 27.2175 17.7116 27.273C17.4895 27.2175 17.2675 27.384 17.101 27.5505ZM30.3666 24.7197L42.0781 42.3703H29.7006L23.9836 34.9326L30.3666 24.7197ZM27.7579 42.4258H17.8781H8.27574L17.7671 29.3266L27.7579 42.4258Z" fill="white" />
                    </svg>
                </EmptyImage>
            </Decoration>
        )
    }

    return (
        <Decoration {...decorationProps}>
            <ImageWrapper ref={ref} objectFit={objectFit} {...props}>
                <img
                  className={ fadeIn ? 'will-fade-in' : null }
                  ref={image}
                  src={src}
                />
            </ImageWrapper>
        </Decoration>
    )
}
