/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/layout/Header/Header"
import "../main.css"
import Footer from "../components/layout/Footer/Footer"

const Default = ({ children, headerColor }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header color={headerColor} />
            <main>{children}</main>
            <Footer />

            {/* <!-- BEGIN PRIVY WIDGET CODE --> */}
            <script type="text/javascript">
                var _d_site = _d_site || 'D02CA87F1610BAC5AB9605A9';
            </script>
            <script src="https://widget.privy.com/assets/widget.js"></script>
            {/* <!-- END PRIVY WIDGET CODE --> */}
        </>
    )
}

Default.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Default
