import React, { useState } from "react"
import styled from "styled-components"
import IconButton from "../buttons/IconButton"
import { Display1, Heading2, Heading1 } from "../typography/Headings"
import Colors from "../utilities/Colors"
import X from "../icons/X"
import NewsletterForm from "./NewsletterForm"
import NewsletterDots from "./NewsletterDots.svg"
import Breakpoints from "../utilities/Breakpoints"
import Bird from "./Bird"
import jsonp from "jsonp"

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 30px 20px;

    z-index: 1000;

    box-sizing: border-box;

    background: ${Colors.orange};
    color: ${Colors.white};

    padding: 75px 30px;

    @media ${Breakpoints.desktop} {
        flex-direction: row;

        justify-content: space-around;

        padding: 200px 60px;

        ${Display1}, ${Heading2} {
            max-width: 550px;
        }

        form {
            max-width: 410px;
        }
    }

    ${IconButton} {
        position: absolute;

        left: 50%;
        top: 25px;

        width: 28px;
        height: 28px;

        transform: translateX(-50%);

        color: ${Colors.white};
    }

    > form {
        margin-top: 50px;

        > div {
            width: 100%;
        }
    }

    &:after {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0;

        content: "";

        background-image: url("${NewsletterDots}");
    }

    .thank-you {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg {
            width: 35%;
            margin-bottom: 40px;
        }

        h1 {
            max-width: none;
        }

        & > h1:first-child {
            margin-bottom: 30px;
        }
    }
`

function queryString(data) {
    return Object.keys(data)
        .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&")
}

export default function Newsletter({ onClose }) {
    const [submitted, setSubmitted] = useState(false)

    function submitForm(data) {
        const formData = {
            MERGE1: data.fullName.split(" ")[0],
            MERGE2: data.fullName.split(" ")[1],
            MERGE0: data.email,
        }

        return jsonp(
            `//otabo.us2.list-manage.com/subscribe/post-json?u=cc68000c1a1b2ade63ad645d3&id=665e283f95&${queryString(
                formData
            )}`,
            { param: "c" },
            (err, d) => {
                if (!err) {
                    setSubmitted(true)
                }
            }
        )
    }

    return (
        <Wrapper>
            <IconButton onClick={onClose}>
                <X />
            </IconButton>
            {!submitted && (
                <>
                    <Display1>
                        Join our mailing list for exclusive updates &amp;
                        resources.
                    </Display1>
                    <NewsletterForm onSubmit={submitForm} />
                </>
            )}

            {submitted && (
                <div className="thank-you">
                    <Bird />
                    <Display1>Step one, complete.</Display1>
                    <Heading1>Let's do this thing.</Heading1>
                </div>
            )}
        </Wrapper>
    )
}
