import styled from "styled-components"
import "./fonts/stylesheet.css"

const BASE_TEXT_STYLE = `
    margin: 0;

    font-family: Founders Grotesk, sans-serif;
    font-weight: normal;

    letter-spacing: 0.02em;
`

export const Text18 = styled.p`
    ${BASE_TEXT_STYLE}

    font-size: 18px;
    line-height: 133%;
`

export const Text16 = styled.p`
    ${BASE_TEXT_STYLE}

    font-size: 16px;
    line-height: 142%;
`

export const Text13 = styled.p`
    ${BASE_TEXT_STYLE}

    font-size: 13px;
    line-height: 142%;
`

const BASE_MONO_STYLE = `
    margin: 0;

    font-family: Founders Grotesk Mono, sans-serif;
    font-weight: 500;

    text-transform: uppercase;
`

export const Mono15Styles = `
    ${BASE_MONO_STYLE}

    font-size: 15px;
    line-height: 140%;

    letter-spacing: 0.075em;
    
`

export const Mono15 = styled.p`
    ${Mono15Styles}
`

export const Mono13Styles = `
    ${BASE_MONO_STYLE}

    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.12em;
`

export const Mono13 = styled.p`
    ${Mono13Styles}
`
